import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '../../services/auth/authentication.service';
import { Actions, ofType } from '@ngrx/effects';
import * as TokenActions from '../../services/auth/authentication-store/authentication.action';
import { takeUntil } from 'rxjs';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { TOAST_STATE, ToastService } from '../../services/toast/toast.service';
import { BaseComponent } from '../base-component/base-component.component';
import { FacebookSignInR, SignInModel, SignUpModel, TokenR } from '../../models/sign-up/sign-up.model';
import { aszfConfirmedValidator } from '../../validators/aszf-confirmed-validator';
import { TranslocoService } from '@ngneat/transloco';
import * as TeacherActions from '../../../teacher/services/teacher/teacher-store/teacher.action';
import { SendEmailModel } from '../../models/email/send-email-to-students.model';
import { LoaderService } from '../../services/loader/loader.service';
import { JwtDecode } from '../../models/jwt-token/jwt-token.model';
import { UtilService } from '../../services/utils/utils.serivce';
import { MenuItemsService } from '../../services/menu-items/menu-items.service';
import { RoleEnum } from '../../enums/roles.enum';

@Component({
  selector: 'sign-up-provider',
  templateUrl: './sign-up-provider.component.html',
  styleUrls: ['./sign-up-provider.component.css']
})
export class SignUpProviderComponent extends BaseComponent implements OnInit, OnDestroy {
  override form: FormGroup = new FormGroup({});
  @Input() error: string | null | undefined;
  emailByIdDTO: SendEmailModel = {};
  signUpModel: SignUpModel = {
    password: '',
    viaProvider: false
  };
  isProviderRegistration = false;
  isGoogleRegistration = false;
  isFacebookRegistration = false;
  facebookSignInR!: FacebookSignInR;
  signInModel: SignInModel = {
    email: '',
    password: ''
  };
  tokenR!: TokenR;
  decodedToken!: JwtDecode;
  selectedRole = RoleEnum.TEACHER;
  
  constructor(
    override store: Store,
    public route: Router,
    public authService: AuthenticationService,
    private utilService: UtilService,
    private menuItemsService: MenuItemsService,
    override loaderService: LoaderService,
    override toastService: ToastService,
    override translocoService: TranslocoService,
    override action$?: Actions
  ) {
    super(store, toastService, action$, translocoService, undefined, undefined, undefined, loaderService);

    action$?.pipe(ofType(TokenActions.LoadGoogleSignUpSuccess), takeUntil(this.destroy$)).subscribe((response) => {
      if (response.data && response.data.email) {
        this.toastService.showToast(TOAST_STATE.success, "Sikeres regisztráció");
        this.sendSuccessfulRegEmail(response.data.email);
        this.googleLogin();
      }
    })

    action$?.pipe(ofType(TokenActions.failure), takeUntil(this.destroy$)).subscribe(() => {
      this.toastService.showToast(TOAST_STATE.warning, "Hiba történt");
      this.loaderService.hideLoader();
    })

    action$?.pipe(ofType(TokenActions.LoadGoogleSignInSuccess), takeUntil(this.destroy$)).subscribe((response) => {
      if (response.data != null && response.data !== undefined) {
        this.tokenR = response.data;
        this.decodedToken = this.utilService.decodeToken(this.tokenR.token) as JwtDecode;

        localStorage.setItem('token', this.tokenR.token);
        if (this.decodedToken.RoleId) {
          localStorage.setItem('roleId', this.decodedToken.RoleId)
        }
        if (this.decodedToken.RoleId) {
          this.menuItemsService.getMenuItems(this.decodedToken.RoleId as string, 2);
        }
        this.loaderService.hideLoader();
        this.toastService.showToast(TOAST_STATE.success, "Sikeres bejelentkezés");

        this.route.navigateByUrl('/');
      }
    })

    action$?.pipe(ofType(TokenActions.LoadFacebookSignInSuccess), takeUntil(this.destroy$)).subscribe((response) => {
      if (response.data != null && response.data !== undefined) {
        this.tokenR = response.data;
        this.decodedToken = this.utilService.decodeToken(this.tokenR.token) as JwtDecode;

        localStorage.setItem('token', this.tokenR.token);
        if (this.decodedToken.RoleId) {
          localStorage.setItem('roleId', this.decodedToken.RoleId)
        }
        if (this.decodedToken.RoleId) {
          this.menuItemsService.getMenuItems(this.decodedToken.RoleId as string, 2);
        }
        this.loaderService.hideLoader();
        this.toastService.showToast(TOAST_STATE.success, "Sikeres bejelentkezés");

        this.route.navigateByUrl('/')
      }
    })

    action$?.pipe(ofType(TokenActions.LoadFacebookSignUpSuccess), takeUntil(this.destroy$)).subscribe((response) => {
      if (response.data && response.data.email) {
        this.toastService.showToast(TOAST_STATE.success, "Sikeres regisztráció");
        this.sendSuccessfulRegEmail(response.data.email);
        this.facebookLogin();
      }
    })

    action$?.pipe(ofType(TeacherActions.failure), takeUntil(this.destroy$)).subscribe(() => {
      this.loaderService?.hideLoader();
      this.toastService.showToast(TOAST_STATE.warning, "Hiba a folyamat során!");
    })
  }

  ngOnInit() {
    this.initFormProvider();
    this.checkProvider();

    this.subscription.add(
      this.form.controls['roleId'].valueChanges.subscribe((roleId: number) => {
        const role = Number(roleId);
        if (role === 1) {
          this.selectedRole = RoleEnum.TEACHER
        } else {
          this.selectedRole = RoleEnum.STUDENT
        }
      }));
  }

  checkProvider() {
    const isGoogleRegistration = JSON.parse(localStorage.getItem('isGoogleRegistration') as string);
    const isFacebookRegistration = JSON.parse(localStorage.getItem('isFacebookRegistration') as string);

    if (isGoogleRegistration && isGoogleRegistration === true) {
      this.signUpModel = JSON.parse(localStorage.getItem('googleSignUpModel') as string);
      this.isProviderRegistration = true;
      this.isGoogleRegistration = true;
    } else if (isFacebookRegistration && isFacebookRegistration === true) {
      this.signUpModel = JSON.parse(localStorage.getItem('facebookSignUpModel') as string);
      this.isProviderRegistration = true;
      this.isFacebookRegistration = true;
    }
    else {
      this.route.navigateByUrl('sign-up');
    }
  }

  sendSuccessfulRegEmail(email: string) {
    this.emailByIdDTO = {};
    this.emailByIdDTO.body = 'Sikeres regisztráció! Köszönjük, hogy regisztráltál az Órafoglaló rendszerébe!';
    this.emailByIdDTO.subject = 'Sikeres regisztráció!';
    this.emailByIdDTO.toAddresses = [];
    this.emailByIdDTO.toAddresses.push(email);
    this.store.dispatch(TeacherActions.sendEmailToAddresses({ data: this.emailByIdDTO }));
  }

  submit() {
    this.signUpModel = JSON.parse(JSON.stringify(this.signUpModel));

    if (this.isProviderRegistration === true) {
      if (this.isFacebookRegistration === true) {
        this.signUpModel.phoneNumber = this.form.controls['phoneNumber'].value;
        this.signUpModel.roleId = this.form.controls['roleId'].value;
        this.signUpModel.isPhonePublic = this.form.controls['isPhonePublic'].value;
        this.signUpModel.isEmailPublic = this.form.controls['isEmailPublic'].value;
        this.signUpModel.isAszfAccepted = this.form.controls['isAszfAccepted'].value;

        this.signInModel = JSON.parse(JSON.stringify(this.signInModel));
        this.signInModel.email = this.signUpModel.email;
        this.signInModel.viaProvider = true;
        this.signInModel.rememberMe = false;
        this.signInModel.email = this.signUpModel.email;
        this.loaderService.showLoader("Regisztráció és Bejelentkezés folyamatban");

        this.store.dispatch(TokenActions.LoadFacebookSignUp({ data: this.signUpModel }));

      }
      else if (this.isGoogleRegistration === true) {
        this.signUpModel.phoneNumber = this.form.controls['phoneNumber'].value;
        this.signUpModel.roleId = this.form.controls['roleId'].value;
        this.signUpModel.isPhonePublic = this.form.controls['isPhonePublic'].value;
        this.signUpModel.isEmailPublic = this.form.controls['isEmailPublic'].value;
        this.signUpModel.isAszfAccepted = this.form.controls['isAszfAccepted'].value;

        this.signInModel = JSON.parse(JSON.stringify(this.signInModel));
        this.signInModel.email = this.signUpModel.email;
        this.signInModel.viaProvider = true;
        this.signInModel.rememberMe = false;
        this.signInModel.googleCredentials = this.signUpModel.googleCredentials;
        this.loaderService.showLoader("Regisztráció és Bejelentkezés folyamatban");
        this.store.dispatch(TokenActions.LoadGoogleSignUp({ data: this.signUpModel }));
      }
    }
  }

  backClicked() {
    window.scrollTo(0, 0);
    this.route.navigateByUrl('sign-up');
  }

  initFormProvider() {
    this.form.addControl('phoneNumber', new FormControl<string | null>('', Validators.required));
    this.form.addControl('roleId', new FormControl<number | null>(1, Validators.required));
    this.form.addControl('isPhonePublic', new FormControl<boolean | null>(false));
    this.form.addControl('isEmailPublic', new FormControl<boolean | null>(false));
    this.form.addControl('isAszfAccepted', new FormControl<boolean | null>(false));
    this.form.addValidators(aszfConfirmedValidator);
  }

  googleLogin() {
    this.store.dispatch(TokenActions.LoadGoogleSignIn({ data: this.signInModel }));
  }

  facebookLogin() {
    this.store.dispatch(TokenActions.LoadFacebookSignIn({ data: this.signInModel }));
  }

  override ngOnDestroy(): void {
    localStorage.removeItem('isGoogleRegistration');
    localStorage.removeItem('isFacebookRegistration');
    localStorage.removeItem('googleSignUpModel');
    localStorage.removeItem('facebookSignUpModel');
  }
}
