<nav class="bg-dark-green w-full sticky top-0 z-10">
  <div class="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
    <div class="relative flex h-14 items-center justify-between">

      <!-- Mobile menu button-->
      @if (mainNavBarItems.length > 0) {
      <div class="absolute inset-y-0 left-0 flex items-center sm:hidden">
        <button type="button" (click)="toggleMobileDropdown()"
          class="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
          aria-controls="mobile-menu" aria-expanded="false">
          <span class="absolute -inset-0.5"></span>
          <span class="sr-only">Open main menu</span>
          <img src="../../../../assets/images/header/hamburger.svg" class="block h-6 w-6" loading="lazy"
            alt="hamburger">
        </button>
      </div>
      }

      <div class="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
        <div class="flex flex-shrink-0 items-center mr-24 lg:mr-0 md:mr-0">
          <img class="h-8 w-auto cursor-pointer" src="./assets/images/orafoglalo_logo.png" alt="Órafoglaló"
            loading="lazy" routerLink="/">
        </div>
        <div class="hidden sm:ml-6 sm:block">
          <div class="flex space-x-4">
            @if (mainNavBarItems.length > 0) {
            @for(menuItem of mainNavBarItems ;track $index){
            <a routerLink="{{menuItem.menuItemLink}}" [class.active]="$index === selectedMenuItem"
              (click)="mainMenuItemClick($index)"
              class="menuitem text-gray-300 hover:bg-gray-700 hover:text-white rounded-md px-3 py-2 text-sm font-medium">
              {{'header.menu.' + menuItem.menuItemName | transloco}}
            </a>
            }
            }
          </div>
        </div>
      </div>

      <div class="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
        <!-- Noticiations dropdown -->
        @if(userId){
        <button type="button" (click)="toggleNotifications()" role="menu"
          class="relative rounded-full bg-gray-800 p-1 text-gray-400 hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
          <span class="absolute -inset-1.5" id="notificationMenu"></span>
          <img src="./assets/images/notification.svg" class="h-6 w-6" alt="anonym" loading="lazy">

          @if (newNotificationsCount > 0) {
          <span
            class="absolute -right-1 -top-1 z-10 items-center rounded-xl bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10">
            {{newNotificationsCount}}
          </span>
          }
        </button>
        <div class="relative">
          <ul (scroll)="onScroll($event)"
            class="dropdown-menu 
            lg:absolute lg:right-8 lg:top-2 
            md:absolute md:right-8 md:top-2 md:w-96
            z-20 mt-2 lg:w-96 w-screen -right-24 top-2 max-h-[500px] absolute
            overflow-y-auto overflow-x-hidden origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
            [ngClass]="{ 'show': isNotificationsOpen }">
            @for (notification of notifications; track $index) {
            <li (click)="newNotificationClick(notification.notificationId)"
              class="rounded-md scroll-item flex hover:cursor-pointer hover:text-light-green hover:bg-light-green/25 lg:ml-0 md:ml-0 ml-6 border-t">
              <div class="w-2/12 content-center">
                @if (notification.profilePicture && notification.profilePicture !=='') {
                <img class="rounded-full w-16 h-16" [src]="getSafeImage(notification.profilePicture)" loading="lazy"
                  alt="pics">
                }
                @else {
                <img src="./assets/images/profile_anonym.svg" class="w-16 h-16" loading="lazy" alt="anonym">
                }
              </div>
              <div class="relative w-10/12">
                <p class="notification-p break-words p-2 w-11/12 text-justify text-dark-green"
                  [class.active]="notification.isNew === true">
                  {{notification.content}}
                </p>
                @if (notification.isNew) {
                <div class="absolute inset-y-0 right-0 flex items-center">
                  <p class="self-center justify-end rounded-full bg-dark-green w-3 h-3">
                  </p>
                </div>
                }
              </div>
            </li>
            }
            @empty {
            <li
              class="rounded-md scroll-item flex hover:cursor-pointer hover:text-light-green hover:bg-light-green/25 lg:ml-0 md:ml-0 ml-6 border-t">
              <div class="w-full">
                <p class="notification-p break-words p-2 w-11/12 text-justify text-dark-green">{{
                  'header.notification_empty' | transloco }}</p>
              </div>
            </li>
            }
            <local-spinner></local-spinner>
            @if(notifications.length>0){
            <div class="items-center p-6" [hidden]="showMoreNotificationsVisible">
              <button type="button" (click)="showMoreNotificationsClick()"
                class="btn-primary w-full lg:ml-0 md:ml-0 ml-6">
                <p class="w-full text-center self-center">{{
                  'common.more' | transloco }}</p>
              </button>
            </div>
            }
          </ul>
        </div>
        }
        <!-- Profile dropdown -->
        <div class="relative ml-3">
          <div>
            <button type="button" (click)="toggleDropdown()"
              class="relative flex rounded-full bg-gray-800 text-sm hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
              id="user-menu-button" aria-expanded="false" aria-haspopup="true">
              <span class="absolute -inset-1.5"></span>
              <span class="sr-only">Open user menu</span>
              @if (safeImg) {
              <img class="rounded-full w-8 h-8" [src]="safeImg" loading="lazy" alt="menu">
              }
              @else {
              <img src="./assets/images/profile_anonym.svg" class="h-8 w-8" alt="anonym" loading="lazy">
              }
            </button>
          </div>
          <ul
            class="dropdown-menu absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
            [ngClass]="{ 'show': isDropdownOpen }">
            @for (menuItem of sideNavBarItems; track $index) {
            <li (click)="setToggleFalse()" class="border-t rounded-md hover:text-light-green hover:bg-light-green/25"
              [routerLink]="menuItem.menuItemLink">
              {{menuItem.menuItemName}}
            </li>
            }
          </ul>
        </div>

        <!-- Languages dropdown -->
        <div class="relative ml-3">
          <div>
            <button type="button" (click)="toggleDropdownLanguages()"
              class="relative flex rounded-full bg-gray-800 hover:bg-gray-500 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
              id="user-menu-button" aria-expanded="false" aria-haspopup="true">
              <span class="absolute -inset-1.5"></span>
              <span class="sr-only">Open language menu</span>
              <img src="./assets/images/language.svg" class="h-8 w-8" alt="anonym" loading="lazy">
            </button>
          </div>

          <ul
            class="dropdown-menu absolute right-0 z-10 mt-2 w-24 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
            [ngClass]="{ 'show': isDropdownLanguagesOpen }">
            <li class="flex hover:cursor-pointer hover:text-light-green hover:bg-light-green/25 border-t rounded-md"
              (click)="setLanguage('hu')">
              <div class="text-center w-full">
                <span class="fi fi-hu h-8 w-8"></span>
              </div>
            </li>
            <!-- <li class="flex hover:cursor-pointer hover:text-light-green border-t rounded-md"
              (click)="setLanguage('en')">
              <div class="text-center w-full">
                <span class="fi fi-gb h-8 w-8"></span>
              </div>
            </li>
            <li class="flex hover:cursor-pointer hover:text-light-green border-t rounded-md"
              (click)="setLanguage('de')">
              <div class="text-center w-full">
                <span class="fi fi-de h-8 w-8"></span>
              </div>
            </li> -->
          </ul>
        </div>
      </div>
    </div>
  </div>

  <!-- Mobile menu, show/hide based on menu state. -->
  <!-- Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" -->
  <div class="sm:hidden mobile-menu" id="mobile-menu" [ngClass]="{ 'show': isMobileDropdownOpen }">
    <div class="space-y-1 px-2 pb-3 pt-2">
      @if(mainNavBarItems && mainNavBarItems.length > 0){
      @for(menuItem of mainNavBarItems ; track $index){
      <a routerLink="{{menuItem.menuItemLink}}" [class.active]="$index === selectedMenuItem"
        (click)="mainMenuItemClick($index)"
        class="menuitem text-gray-300 hover:bg-gray-700 hover:text-white block rounded-md px-3 py-2 text-base font-medium">
        {{'header.menu.' + menuItem.menuItemName | transloco}}
      </a>
      }
      }
    </div>
  </div>
</nav>