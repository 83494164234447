import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../shared/services/auth/authentication.service';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { TOAST_STATE, ToastService } from '../../../shared/services/toast/toast.service';
import { LoaderService } from '../../../shared/services/loader/loader.service';
import { BaseComponent } from '../../../shared/components/base-component/base-component.component';
import { FacebookSignInR, SignUpModel } from '../../models/sign-up/sign-up.model';
import { TranslocoService } from '@ngneat/transloco';

declare var FB: any;
declare var gapi: any;

@Component({
  selector: 'sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.css']
})
export class SignUpComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input() error: string | null | undefined;
  signUpModel: SignUpModel = {
    password: '',
    viaProvider: false
  };
  isProviderRegistration = false;
  isGoogleRegistration = false;
  isFacebookRegistration = false;
  facebookSignInR!: FacebookSignInR;
  googleAccessToken = "";

  constructor(
    override store: Store,
    public route: Router,
    public authService: AuthenticationService,
    override toastService: ToastService,
    override translocoService: TranslocoService,
    override loaderService: LoaderService,
    private ref: ChangeDetectorRef,
    override action$?: Actions,
  ) {
    super(store, toastService, action$, translocoService,undefined,undefined,undefined,loaderService);
  }

  ngOnInit() {
    this.clearStorage();
  }

  clearStorage(){
    localStorage.removeItem('isGoogleRegistration');
    localStorage.removeItem('isFacebookRegistration');
    localStorage.removeItem('googleSignUpModel');
    localStorage.removeItem('facebookSignUpModel');
  }

  googleRegistrationClick() {
    this.loaderService.showLoader('Google regisztráció');
    gapi.load('auth2', () => {
      gapi.auth2.authorize({
        client_id: '1047061689417-35t06epi74785n6k54gnf4kv4nv4v9u2.apps.googleusercontent.com',
        scope: 'email profile openid',
        plugin_name: 'of_login',
        response_type: 'id_token permission'
      }, (response: any) => {
        if (response.error) {
          this.loaderService.hideLoader();
          this.ref.detectChanges();
          return;
        }
        this.isGoogleRegistration = true;
        this.googleAccessToken = response.id_token;
        this.createGoogleCredentials();
        this.toastService.showToast(TOAST_STATE.success, "Sikeres bejelentkezés, kérlek töltsd ki a további adatokat!");
        this.loaderService.hideLoader();
        this.route.navigateByUrl('/sign-up-provider');
        this.ref.detectChanges();
      }
      );
    });
  }

  createGoogleCredentials(){
    this.signUpModel = JSON.parse(JSON.stringify(this.signUpModel));

    this.signUpModel.googleCredentials = this.googleAccessToken;
    this.signUpModel.viaProvider = true;
    localStorage.setItem('googleSignUpModel', JSON.stringify(this.signUpModel));
    localStorage.setItem('isGoogleRegistration', JSON.stringify(this.isGoogleRegistration));
  }

  createFacebookCredentials(){
    this.signUpModel.firstName = this.facebookSignInR.first_name;
    this.signUpModel.lastName = this.facebookSignInR.last_name;
    this.signUpModel.email = this.facebookSignInR.email;
    this.signUpModel.viaProvider = true;

    localStorage.setItem('facebookSignUpModel', JSON.stringify(this.signUpModel));
    localStorage.setItem('isFacebookRegistration', JSON.stringify(this.isFacebookRegistration));
  }

  facebookRegistrationClick() {
    FB.login((response: any) => {
      this.statusChangeCallback(response);
    })
  }

  ofRegistrationClick() {
    this.route.navigateByUrl('/sign-up-of')
  }

  statusChangeCallback(response: { status: string; }) {
    this.loaderService.showLoader('Facebook regisztráció folyamatban');
    if (response.status === 'connected') {
      FB.api('/me', { fields: 'first_name, last_name, email' }, (response: FacebookSignInR) => {
        if (response) {
          this.facebookSignInR = response;
          this.isProviderRegistration = true;
          this.isFacebookRegistration = true;
          this.createFacebookCredentials();
          this.loaderService.hideLoader();
          this.route.navigateByUrl('/sign-up-provider');
          this.toastService.showToast(TOAST_STATE.success, "Sikeres bejelentkezés, kérlek töltsd ki a további adatokat!");
          this.ref.detectChanges();
        }
      });
    } else {
      this.loaderService.hideLoader();
      this.toastService.showToast(TOAST_STATE.danger,'Hiba a bejelentkezés során')
      this.ref.detectChanges();
    }
  }
}
